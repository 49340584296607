@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  padding-top: 24px;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    padding-top: 16px;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.filterHeader {
  line-height: 24px;
}

.labelWrapper {
  display: inline-block;
  max-width: calc(100% - 16px);
}

.labelButton {
  /* Override button styles */
  outline: none;
  line-height: 24px;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
}

.label:hover {
  cursor: pointer;
}

.openSign {
  float: right;
}

.clearButton {
  composes: h5 from global;
  margin: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 4px 0 4px 0;
  }
}
