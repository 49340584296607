@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  height: 48px;
  margin-bottom: 16px;
  border: 1px solid var(--borderColor);
  border-radius: 8px;
  background-color: white;
}

.mobileIcon {
  display: flex;
  width: 14px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.mobileInput {
  flex-grow: 1;
  border: none;
}

.keywordSearchWrapper {
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 4px 10px 4px 0px;
  flex: 1;
}

.searchSubmit {
  border: 0;
  padding: 11px 0px 13px 8px;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;

    & .iconSvgGroup {
      stroke: var(--matterColor);
    }
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--matterColorLight);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.dividerWrapper {
  display: flex;
  align-items: center;
}

.verticalDivider {
  height: 30px;
  border-left: 1px solid #ced4da;
}

.buttons {
  display: flex;
  padding: 12px;
}

.filterIcon {
  height: 24px;
  width: 24px;
}

.filterIcon:hover {
  cursor: pointer;
}

.searchIcon {
  width: 16px;
  height: 16px;
}
