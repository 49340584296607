.root {
  width: 100%;
}

.formRoot {
  width: 100%;
}

.formTypeWrapper {
  width: 100%;
}

.selectionWrapper {
  height: 44px;
  border-bottom: 1px solid #e9ecef;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formCategoryWrapper {
  margin-left: 24px;
}

.formSubCategoryWrapper {
  margin-left: 24px;
}

.optionLabel {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #212529;
}

.selectedOptionLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: #212529;
}

.selectedIcon {
  height: 24px;
  width: 24px;
}
