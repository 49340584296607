@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;

  position: relative;
}

.listingCardsMapVariant {
  padding: 0 0 96px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.listingCards {
  padding: 0 0 24px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1220px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.listingCard {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;

  &:hover {
    text-decoration: none;
  }
}

.listingImage {
  width: 100%;
  height: 264px;
  object-fit: cover;
  overflow: overlay;
  border-radius: 12px;
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
}

.resultsHeader {
  display: none;

  @media (--viewportMedium) {
    height: 52px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    position: sticky;
    top: 0;
  }
}
