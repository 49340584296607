@import '../../styles/customMediaQueries.css';

.root {
  min-height: 56px;
  max-height: 56px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  filter: drop-shadow(0px 4px 6px rgba(24, 39, 75, 0.06));
  background: white;
  z-index: 1;

  @media (--viewportMedium) {
    min-height: var(--topbarHeightDesktop);
    max-height: var(--topbarHeightDesktop);
  }
}

.returnAction {
  width: 24px;
  height: 24px;
}

.backActionWrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;

  @media (--viewportMedium) {
    padding: 20px;
  }
}

.backActionWrapper:hover {
  cursor: pointer;
}

.topBarTitle {
  margin: 0;
  line-height: 32px;
}

.topBarTitleClickable {
  margin: 0;
  line-height: 32px;
}

.topBarTitleClickable:hover {
  cursor: pointer;
}

.menu {
  composes: h5 from global;
  color: var(--matterColor);

  padding: 16px;
  position: absolute;
  right: 0;

  display: flex;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menuIcon {
  width: 24px;
  height: 24px;
}

.closeIcon {
  width: 19px;
  height: 19px;
}

.mobileModalContainer {
  padding: 0 0 48px 0 !important;
}

.mobileModal {
  position: relative !important;
  height: 100dvh;
  z-index: 100;

  @media (--viewportMedium) {
    display: none;
  }
}

.mobileModalHeader {
  display: none !important;
}

.home {
  position: absolute;
  left: 0;
  display: flex;
  padding: 16px;
}

.logo {
  height: 16px;
}
