@import '../../../styles/customMediaQueries.css';

.root {
  display: none;
  background-color: white;

  position: sticky;
  top: var(--topbarHeightDesktop);
  flex: 0 0 260px;
  padding-bottom: 16px;
  padding-left: 6px;

  height: calc(100dvh - 128px);
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
  }
}

.root::-webkit-scrollbar {
  display: none;
}

.filters {
  & > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
  }
}

.searchFiltersPanelClosed {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;

  display: inline-block;
  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);

  display: inline-block;
  padding: 9px 16px 9px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}
